import React from "react";
import {Link, Route, Routes} from 'react-router-dom';
import Home from './Home'

const App = () => {
  return(
    <>
    <Routes>
      <Route path="/" element={<Home/>}/>
    </Routes>
    </>
  )
}

export default App;