import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Home = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const accessKey = 'fmBvQIJBusajLAvuc2GXhgLiAbrFef8SLyv9FXqDoV4'; // Replace with your Unsplash access key

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const queries = ['beautiful women', 'attractive people', 'models', 'fashion', 'beauty'];
        const allImages = [];

        for (const query of queries) {
          const response = await axios.get('https://api.unsplash.com/search/photos', {
            params: {
              query,
              per_page: 100, // Fetch 100 images for each query
              page: 1,
            },
            headers: {
              Authorization: `Client-ID ${accessKey}`
            }
          });

          allImages.push(...response.data.results);
        }

        setImages(allImages);
      } catch (error) {
        console.error('Error fetching images from Unsplash:', error);
      }
    };

    fetchImages();
  }, [accessKey]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const smashImage = () => {
    nextImage();
    alert('You smashed!')
  };

  const passImage = () => {
    nextImage();
    alert('You passed!')
  };

  return (
    <>
      <div className='iden'>
        <p>A product of <a href='https://wa.me/+233257804548'>Alex Nyanteng</a></p>
      </div>

      <div className='choose'>
        <p>Smash or Pass</p>

        {images.length > 0 && (
          <img
            src={images[currentIndex].urls.regular}
            alt={images[currentIndex].description || 'Unsplash Image'}
            style={{ width: '300px', height: '50vh' }}
          />
        )}
      </div>

      <div className='btn'>
        <button onClick={smashImage}>Smash</button>
        <button onClick={passImage}>Pass</button>
      </div>
    </>
  );
}

export default Home;
